<template>
  <div class="hero is-fullheight" id="login">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered v-centered">
          <div class="column is-5 is-flex">
            <figure class="avatar">
              <img class="image" src="@/assets/Intelligential-logo-sincirculo.png">
            </figure>
          </div>
          <div class="column-line is-hidden-mobile">
          </div>
          <div class="column is-6">
            <div class="padding-section">
              <h1 class="title is-3">Bienvenido</h1>
            </div>
            <p>Smart Native</p>
            <div class="columns is-centered v-centered">
              <div class="column is-8">
                <div class="box">
                  <form
                    @submit.prevent="loginUser"
                  >
                    <div class="field">
                      <div class="control">
                        <input class="input"
                          type="email"
                          name="email"
                          placeholder="Correo electrónico"
                          required
                          autofocus=""
                          autocomplete="off"
                          v-model="email"
                          data-testid="field-login-email"
                          >
                      </div>
                    </div>
                      <div class="field">
                        <div class="control has-icons-right">
                          <input
                            class="input"
                            :type="!showPassword ? 'password' : 'text'"
                            required
                            placeholder="Contraseña"
                            name="password"
                            v-model="pass"
                            data-testid="field-login-password"
                          >
                          <a class="icon is-small is-right" @click="viewPassword()">
                            <i class="far fa-eye" v-if="!showPassword"></i>
                            <i class="far fa-eye-slash" v-else></i>
                          </a>
                        </div>
                      </div>
                    <button :class="{'is-loading': loading}" class="button is-block is-medium is-fullwidth" data-testid="action-login-getIn" >Ingresar</button>
                  </form>
                </div>
                <p class="has-text-grey">
                  <router-link class="link" :to="{ name: 'forgot-password'}" >¿Olvidaste tu contraseña?</router-link>
                </p>
              </div>
            </div>
            <div class="notification is-danger" v-if="error">
              {{error}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      email: null,
      pass: null,
      loading: false,
      showPassword: false,
      error: null
    }
  },
  methods: {
    ...mapActions(['signin']),
    async loginUser () {
      this.error = null
      this.loading = true
      let ip = await fetch('https://api.ipify.org?format=json')
      ip = await ip.json()
      ip = ip.ip
      const data = {
        email: this.email,
        password: this.pass,
        ip
      }
      this.signin(data)
        .then(resp => {
          this.loading = false
          if (resp.error) {
            this.error = resp.message
          }
        })
    },
    viewPassword () {
      this.showPassword = !this.showPassword
    }
  }
}
</script>
<style scoped>
  a.icon{
    cursor: pointer;
    pointer-events: auto !important;
  }
  #login {
    background-color: #27384e;
  }
  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .column-image {
    margin: auto;
  }
  .image {
    width: 250px;
  }
  .login {
    align-items: center;
    justify-content: center;
  }
  .box {
    background: transparent;
    box-shadow: none;
    padding: 40px;
  }
  .button {
    margin-top: 50px;
    background: #00ace0;
    border-color: #00ace0;
    font-family: Montserrat, Arial, Helvetica;
    color: #fff;
  }
  .button:hover {
    background: #0093c6;
    border-color: #0093c6;
  }
  .field input {
    font-size: 0.75rem;
    padding: 1.20rem 0.5rem;
  }
  .hero-body {
    padding: 0rem 1.5rem !important;
  }
  .vcenteded {
    display: flex;
    min-height: 100vh;
    align-items: center;
  }
  h1 {
    font-size: 22px;
    color: #fff;
  }
  p {
    font-size: 16px;
    color: #fff;
  }
  .column-line {
    border-right: 1px solid #374f6e;
    border-left: 1px solid #141d29;
  }
  .padding-section {
    margin: 20px 0;
  }
  .link, .link:focus, .link:active, .link:visited {
    color: #FFFFFF;
  }
</style>
